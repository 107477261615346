import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'

const routes = [{
  path: '/:lang(en|cn)?',
  children: [
    {
      path: '',
      name: 'Home',
      component: Home
    },
    {
      path: 'leaderboard',
      name: 'Leaderboard',
      component: () => import('@/views/Leaderboard')
    },
    {
      path: 'leaderboard/bg',
      name: 'LeaderboardWithBg',
      component: () => import('@/views/Leaderboard'),
      props: { displayBg: true }
    },
    {
      path: 'team/:id',
      name: 'TeamOverlay',
      component: () => import('@/views/TeamOverlay'),
      props: true
    }
  ]
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
