<template>
  <div>
    <transition name="fade" mode="out-in">
      <DungeonRuns v-if="isLoggedIn" />
      <Login v-else-if="isLoggedIn !== undefined" />
    </transition>
  </div>
</template>


<script>
import { auth } from '@/firebase';
import DungeonRuns from '@/views/DungeonRuns';
import Login from '@/views/Login';

export default {
  components: {
    DungeonRuns,
    Login
  },
  data() {
    return {
      isLoggedIn: undefined
    }
  },
  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
