<template>
  <div>
    <div id="nav">
      <router-link :to="`/${$i18n.locale}`">
        {{ $t('Home') }}</router-link> |
      <router-link :to="`/${$i18n.locale}/leaderboard`">
        {{ $t('Leaderboard') }}</router-link> |
      <router-link :to="`/${$i18n.locale}/team/1`">
        {{ $t('Team') }}</router-link> |
      <a href="#" @click.prevent="clearActive">
        {{ $t('Clear Active') }}</a> |
      <a href="#" @click.prevent="logout">
        {{ $t('Logout') }}</a>
    </div>

    <AddDungeonRun/>

    <table v-if="dungeonRuns.length > 0">
      <tr>
        <th>{{ $t('Team') }}</th>
        <th>{{ $t('Dungeon') }}</th>
        <th class="level">{{ $t('Level') }}</th>
        <th class="time">{{ $t('Time') }} ({{ $t('ms') }})</th>
      </tr>
      <transition-group name="fade" mode="out-in">
        <tr v-for="run in dungeonRuns" :key="run.key">
          <td>{{ getTeamName(run.teamId) }}</td>
          <td>{{ $t(getDungeonName(run)) }}</td>
          <td class="level">{{ run.keystoneLevel }}
            <span v-if="run.special">(WR)</span></td>
          <td class="time">{{ run.time }}</td>
          <td class="actions">
            <button type="button"
              @click="toggleSpecial(run)">WR</button>
            <button type="button"
              @click="deleteRun(run)">{{ $t('Delete') }}</button>
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { getLocalizedDb } from '@/utils/db';
import AddDungeonRun from '@/views/AddDungeonRun';
import { Dungeons } from '@/data/wow';

export default {
  components: {
    AddDungeonRun
  },
  data() {
    return {
      teams: {},
      teamsDb: null,
      runsDb: null,
      dungeonRuns: [],
      dungeons: Dungeons
    }
  },
  created() {
    this.teamsDb = getLocalizedDb('teams', this.$i18n)
    this.teamsDb.onSnapshot((snapshotChange) => {
      this.teams = [];
      snapshotChange.forEach((doc) => {
        this.teams[doc.id] = doc.data().name;
      });
    });
    this.runsDb = getLocalizedDb('runs', this.$i18n)
    this.runsDb.orderBy('created', 'desc')
      .onSnapshot((snapshotChange) => {
        this.dungeonRuns = [];
        snapshotChange.forEach((doc) => {
          this.dungeonRuns.push({
            key: doc.id,
            teamId: doc.data().teamId,
            name: doc.data().dungeonName,
            keystoneLevel: doc.data().keystoneLevel,
            challengeModeId: doc.data().challengeModeId,
            time: doc.data().time,
            special: doc.data().special
          });
        });
      });
  },
  methods: {
    getTeamName(teamId) {
      return this.teams[teamId] || 'N/A';
    },
    getDungeonName(dungeonRun) {
      const dungeon = this.dungeons.find(
        d => d.challengeModeId === dungeonRun.challengeModeId);
      if (dungeon) {
        return dungeon.name;
      } else {
        return dungeonRun.name;
      }
    },
    deleteRun(run) {
      if (window.confirm("Are you sure?")) {
        this.runsDb.doc(run.key).delete();
      }
    },
    toggleSpecial(run) {
      this.runsDb.doc(run.key).update({
        special: !run.special
      });
    },
    clearActive() {
      this.teamsDb.get().then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          doc.ref.update({
            currentMapId: 0,
            currentChallengeModeId: 0
          })
        });
      });
    },
    logout() {
      auth.signOut();
    }
  }
}
</script>

<style scoped>
table {
  margin: 20px auto;
  border-spacing: 0;
  border: 1px solid #eee;
  border-radius: 10px;
}

td, th {
  width: 208px;
  padding: 5px 10px;
  text-align: left;
}

tr:nth-child(even){
  background: #eee;
}

button {
  font-size: 14px;
  line-height: 14px;
  height: 24px;
  cursor: pointer;
}

.level {
  width: 80px;
}

.time {
  width: 100px;
}

.actions {
  width: 100px;
  text-align: right;
}

.actions > button {
  margin-right: 4px;
}

.actions > button:last-child {
  margin-right: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
