<template>
  <div class="login">
    <img src="@/assets/logo.png">
    <form @submit.prevent="userLogin">
      <input type="password" v-model="user.password"
        placeholder="Password" required>
      <button type="submit">Login</button>
    </form>
  </div>
</template>


<script>
import { auth } from '@/firebase';

export default {
  data() {
    return {
      user: {   
        username: 'gcdtv',
        password: ''
      }
    };
  },
  methods: {
    userLogin() {
      const url = 'https://us-central1-mythic-tgp.cloudfunctions.net/auth';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
          username: this.user.username,
          password: this.user.password
        })
      };

      this.errorMsg = null;

      fetch(url, options)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              if (data.token) {
                auth.signInWithCustomToken(data.token);
              }
            });
          } else {
            alert('Invalid login credentials.');
          }
        });
    }
  }
};
</script>

<style scoped>
button, input {
  box-sizing: border-box;
  height: 26px;
}

button {
  cursor: pointer;
}

input {
  margin-right: 8px;
}

.login {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
}
</style>
