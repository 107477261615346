<template>
  <router-view/>
</template>

<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'petite-vue-i18n'

export default {
  setup() {
    const { t } = useI18n()
    return { t }
  },
  created() {
    const router = useRouter()
    router.afterEach((to) => {
      this.$i18n.locale = to.params.lang || 'en'
    })
  }
}
</script>

<style>
@font-face {
  font-family: Blizz Quadrata;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/blizz-quadrata.eot);
  src: url(./assets/fonts/blizz-quadrata.woff2) format('woff2');
}

@font-face {
  font-family: Semplicita Pro;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/semplicita.woff2) format('woff2');
}

@font-face {
  font-family: Semplicita Pro;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/semplicita-bold.woff2) format('woff2');
}

@font-face {
  font-family: Blizz Quadrata;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/MicrosoftYaHeiUI.woff2) format('woff2');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: Semplicita Pro;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/semplicita.woff2) format('woff2');
  unicode-range: U+4E00-9FFF;
}

@font-face {
  font-family: Semplicita Pro;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/semplicita-bold.woff2) format('woff2');
  unicode-range: U+4E00-9FFF;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#app {
  font-family: Semplicita Pro, Open Sans, Arial, Helvetica, sans-serif;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
