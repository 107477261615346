<template>
  <form @submit.prevent="onFormSubmit">
    <label for="team">{{ $t('Team') }}: </label>
    <select v-model="dungeonRun.teamId" id="team" required>
      <option disabled value="">Please select</option>
      <option v-for="team in teams" :key="team.key"
        :value="team.key">{{ team.name }}</option>
    </select>

    <label for="dungeon">{{ $t('Dungeon') }}: </label>
    <select v-model="selectedDungeon" id="dungeon" required>
      <option disabled value="">Please select</option>
      <option v-for="dungeon in dungeons" :value="dungeon"
        :key="dungeon.challengeModeId">
        {{ $t(dungeon.name) }}
      </option>
    </select>

    <label for="keystoneLevel">{{ $t('Level') }}: </label>
    <input type="number" min="2" id="keystoneLevel"
      v-model.number="dungeonRun.keystoneLevel" required>

    <label for="time">{{ $t('Time') }}: </label>
    <input type="number" min="0" id="time"
      v-model.number="dungeonRun.time" required>

    <button type="submit">Add Run</button>
  </form>
</template>

<script>
import { getLocalizedDb } from '@/utils/db';
import { Dungeons } from '@/data/wow';

export default {
  data() {
    return {
      dungeons: [...Dungeons],
      selectedDungeon: '',
      dungeonRun: {
        teamId: '',
        challengeModeId: '',
        dungeonName: '',
        keystoneLevel: 25,
        time: 0,
        created: null
      },
      teams: []
    }
  },
  created() {
    const teamsDb = getLocalizedDb('teams', this.$i18n)
    teamsDb.onSnapshot((snapshotChange) => {
      this.teams = [];
      snapshotChange.forEach((doc) => {
        this.teams.push({
          key: doc.id,
          name: doc.data().name
        });
      });
    });
  },
  methods: {
    onFormSubmit(event) {
      event.preventDefault();
      this.dungeonRun.created = new Date();
      this.dungeonRun.challengeModeId = this.selectedDungeon.challengeModeId;
      this.dungeonRun.dungeonName = this.selectedDungeon.name;
      const runsDb = getLocalizedDb('runs', this.$i18n)
      runsDb.add(this.dungeonRun).then(() => {
        this.selectedDungeon = '';
        this.dungeonRun.teamId = '';
        this.dungeonRun.dungeonName = '';
        this.dungeonRun.keystoneLevel = 25;
        this.dungeonRun.time = 0;
        this.dungeonRun.created = null;
      }).catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>
button, input, select {
  box-sizing: border-box;
  height: 26px;
}

button, select {
  cursor: pointer;
}

input, select {
  margin-right: 12px;
}

#keystoneLevel {
  width: 60px;
}

#time {
  width: 90px;
}
</style>
