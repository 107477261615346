import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAAYpSWKV1yqLOw9DLrNXP5-DpVG6ICWMc',
  authDomain: 'mythic-tgp.firebaseapp.com',
  projectId: 'mythic-tgp'
})

export const db = firebaseApp.firestore()
export const auth = firebaseApp.auth()
