import { createI18n } from 'petite-vue-i18n'
import cnLocale from '@/locales/cn'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  missingWarn: false,
  messages: {
    en: {},
    cn: cnLocale
  }
})

export default i18n
